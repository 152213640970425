<template>
    <div>
    <Header />
    <router-view></router-view>
    <Footer />
    <QuoteRequest />
    </div>
</template>

<script>
const Header = () => import("@/components/Header");
const Footer = () => import("@/components/Footer");
const QuoteRequest = () => import("@/components/QuoteRequest");



export default {
    name: "AppLayout",
    components: {
        Header,
        Footer,
        QuoteRequest
    },
}
</script>

<style scoped>
@import "../assets/css/style.css";
</style>